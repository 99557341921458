@mixin bottom-shadow {
  box-shadow: 0 4px 2px -2px $black-shadow;
}

@mixin top-shadow {
  box-shadow: 0 -4px 2px -2px $black-shadow;
}

.bottom-shadow { @include bottom-shadow; }
.top-shadow { @include top-shadow; }

@mixin custom-box-shadow($blur, $color, $h-offset: 0px, $v-offset: 4px) {
  box-shadow: $h-offset $v-offset $blur $color;
}

.black-shadow {
  @include custom-box-shadow(6px, $black-shadow);
}
  
.grey-shadow {
  @include custom-box-shadow(6px, $grey-shadow);
}
  