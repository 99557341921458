
// Anchor links
@mixin link($color) {
  color: $color;
  background: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  text-decoration: none;
  @include media-breakpoint-up(lg) {
    &:hover,
    &:hover:enabled {
      @include transform(translateY(-3px));
      @include transition(.3s ease);
      outline: none;
      box-shadow: none;
      text-decoration: none;
    }
  }
  &:hover,
  &:hover:enabled {
    color: $color;
    outline: none;
    box-shadow: none;
    text-decoration: none;
  }
  &:active {
    color: lighten($color, 15%);
    outline: none;
    box-shadow: none;
    text-decoration: none;
  }
  &:disabled {
    opacity: .5;
    cursor: not-allowed;
    outline: none;
    box-shadow: none;
    text-decoration: none;
  }
  &:visited {
    color: $color;
    outline: none;
    box-shadow: none;
    text-decoration: none;
  }
  &:link {
    color: $color;
    outline: none;
    box-shadow: none;
    text-decoration: none;
  }
}

@mixin link-p-darkest { @include link($primary-darkest) }
@mixin link-p-md-dark { @include link($primary-md-dark) }

.link-p-darkest { @include link-p-darkest }
.link-p-md-dark { @include link-p-md-dark }