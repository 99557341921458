// Color palette

$primary-darkest:#63460D;
$primary-md-dark:#D29C32;
$primary-dark: #EED49F;
$primary-main:#F4E4C3;
$primary-light:#F7EDDA;
$primary-md-light:#FFF0DB;
$primary-lightest:#FDFAF5;

$secondary-dark: #DD6868;
$secondary-main: #E5315D;
$secondary-light: #F7DADA;
$secondary-lightest: #FFF3F3;

$ternary-dark: #279856;
$ternary-main: #68DD98;
$ternary-light: #BEF1D3;
$ternary-lightest: #DAF7E6;

$white: #ffffff;
$grey : #F4F4F4;
$black: #000000;
$primary-grey: #B0A285;

// Default property

// Box-shadow
$black-shadow: rgba(0, 0, 0, .20);
$brown-shadow: rgba(99, 70, 13, .20);
$grey-shadow: rgb(74 74 78 / 12%);