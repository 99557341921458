@mixin alert($color: $primary-md-dark, $bg: $primary-lightest) {
  display: flex;
  width: 100%;
  color: $color;
  background-color: $bg;
  padding: 10px;
  border-radius: 5px;
  fa-icon, i, .icon {
    width: 25px;
    align-self: center;
  }
  .message {
    align-self: center;
  }
}

.c-alert.danger {
  @include alert($secondary-main, $secondary-lightest);
}

.c-alert.warning {
  @include alert($primary-main, $primary-lightest);
}