.pos-relative {
    position: relative;
}

.fixed-top {
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
}

.fixed-bottom {
    position: fixed;
    width: 100%;
    bottom: 0px;
    left: 0px;
    right: 0px;
}

.dis-flex {
    display: flex;
    .flex-item {
        flex: 1;
    }
    .flex-item-2 {
        flex: 2;
    }
    .align-center { align-self: center; }
    .align-top { align-self: flex-start; }
    .align-bottom { align-self: flex-end; }
}