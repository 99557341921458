// Overlay
.dark-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .20);
  z-index: 1030;
}

.disabled {
  opacity: .5;
  user-select: none;
  pointer-events: none;
}