@mixin content-card {
    background-color: $white;
    @include custom-box-shadow(6px, $grey-shadow);
    .header {
        position: relative;
        padding: 15px;
        border-bottom: 1px solid $primary-main;
        .title {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 5px;
        }
        .subtitle {
            opacity: .5;
        }
        
        &.no-border {
            border: none;
            border-bottom: none;
        }
    }
    .content {
        position: relative;
        padding: 30px 15px;
        &.p-0 {
            padding: 0px;
        }
    }
}

.content-card {
    @include content-card;
}