@include media-breakpoint-down(md) {
  .m-container {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
}

.m-section {
  padding-top: 25px;
  padding-bottom: 25px;
}

.d-section {
  padding-top: 50px;
  padding-bottom: 50px;
}