@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

.ql-toolbar.ql-snow {
    border: 1px solid $primary-main;
    border-top-left-radius:  5px;
    border-top-right-radius:  5px;
}

.ql-container.ql-snow {
    border: 1px solid $primary-main;
    border-bottom-left-radius:  5px;
    border-bottom-right-radius:  5px;
}