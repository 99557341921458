@mixin page-header {
    position: relative;
    margin-bottom: 30px;
    .title {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 5px;
    }
    .subtitle {
        opacity: .5;
    }
}

.page-header {
    @include page-header;
}