.p-input-wrapper,
.p-select-wrapper {
  position: relative;
  margin-bottom: 25px;

  input,
  select,
  textarea {
    padding: 6px 12px;
    display: block;
    margin: 0;
    padding-left: 10px;
    width: 100%;
    border: 1px solid $primary-main;
    border-radius: 5px;
    -webkit-appearance: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: $primary-darkest;
    background-color: $white;
    &:focus {
      outline: none;
      box-shadow: none;
      border: 1px solid $primary-md-dark;
    }
    &::placeholder {
      color: $primary-darkest;
      opacity: 0.5; /* Firefox */
    }
    &:-ms-input-placeholder {
      color: $primary-darkest; /* Internet Explorer 10-11 */
      opacity: 0.5;
    }
    &::-ms-input-placeholder {
      color: $primary-darkest; /* Microsoft Edge */
      opacity: 0.5;
    }
  }

  input,
  select {
    height: 40px;
  }

  select:invalid,
  select.novalue {
    color: $primary-darkest;
    opacity: 0.5;
  }

  select:disabled {
    color: $primary-darkest;
    opacity: 0.5;
  }

  option {
    color: $primary-darkest;
  }

  label {
    display: block;
    position: absolute;
    opacity: 0;
    left: 4px;
    top: -4px;
    background-color: $white;
    padding: 0 5px;
    color: $primary-md-dark;
    transition: 0.2s ease-in-out transform;
    font-size: 12px;
  }

  input + label,
  select + label,
  textarea + label {
    display: block;
    position: absolute;
    opacity: 0;
    left: 4px;
    top: -2px;
    background-color: $white;
    padding: 0 5px;
    color: $primary-md-dark;
    transition: 0.2s ease-in-out transform;
    font-size: 12px;
  }

  input:placeholder-shown + label,
  select:disabled + label,
  select[required]:invalid + label,
  select.novalue + label,
  textarea:placeholder-shown + label {
    visibility: hidden;
    z-index: -1;
    transition: 0.2s ease-in-out;
  }

  select[required]:invalid,
  select.novalue {
    color: $primary-darkest;
    opacity: 0.5;
  }

  select {
    color: $primary-darkest;
  }

  input:not(:placeholder-shown) + label,
  input:focus:not(:placeholder-shown) + label,
  textarea:not(:placeholder-shown) + label,
  textarea:focus:not(:placeholder-shown) + label,
  select + label {
    visibility: visible;
    z-index: 1;
    opacity: 1;
    transform: translateY(-5px);
    transition: 0.2s ease-in-out transform;
  }

  .invalid-message {
    color: $secondary-main;
    margin-top: 4px;
    font-size: 12px;
    display: none;
  }

  .icon-reveal {
    position: absolute;
    right: 10px;
    top: 12px;
    color: $primary-darkest;
    opacity: 0.5;
    cursor: pointer;
  }

  &.is-invalid {
    input,
    select,
    textarea {
      border: 1px solid $secondary-main;
      color: $secondary-main;
      &::placeholder {
        color: $secondary-main;
        opacity: 1; /* Firefox */
      }
      &:-ms-input-placeholder {
        color: $secondary-main; /* Internet Explorer 10-11 */
      }
      &::-ms-input-placeholder {
        color: $secondary-main; /* Microsoft Edge */
      }
    }

    input + label,
    textarea + label {
      color: $secondary-main;
    }

    .invalid-message {
      display: block;
    }

    label,
    .icon-reveal {
      color: $secondary-main;
    }
  }

  &.search {
    margin-bottom: 0px;
    position: relative;
    .icon {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      color: $primary-md-dark;
    }
    input,
    textarea {
      padding-right: 30px;
    }
  }
}

.p-select-wrapper {
  width: 100%;
  &:after {
    font-family: FontAwesome;
    content: "\f078";
    font-size: 10px;
    color: $primary-dark;
    position: absolute;
    top: 14px;
    right: 10px;
    pointer-events: none;
  }
}

@include media-breakpoint-down(xs) {
  .p-input-wrapper,
  .p-select-wrapper {
    margin-bottom: 20px;
  }
}

.p-checkbox-wrapper {
  input[type="checkbox"] {
    -webkit-appearance: none;
    background-color: $white;
    border: 1px solid $primary-md-dark;
    padding: 8px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    display: inline-block;
    color: $white !important;
    cursor: pointer;
  }

  input[type="checkbox"]:focus {
    outline: 0;
  }

  input[type="checkbox"]:active,
  input[type="checkbox"]:checked:active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  }

  input[type="checkbox"]:checked {
    background-color: $primary-md-dark;
    border: 1px solid $primary-md-dark;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
      inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  }

  input[type="checkbox"]:checked::before {
    content: "\f00c";
    font-size: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $white !important;
    font-family: "FontAwesome";
  }

  label {
    color: $primary-darkest;
    font-size: 16px;
    margin-left: 5px;
    margin-bottom: 0px;
    vertical-align: middle;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    cursor: pointer;
  }
}

.p-switch-wrapper {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  margin: 0;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: $primary-md-dark;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $primary-md-dark;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
  }

  &.slider-s-main {
    input:checked + .slider {
      background-color: $secondary-main;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px $secondary-main;
    }
  }

  &.switch-sm {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 15px;
    margin: 0;

    .slider:before {
      position: absolute;
      content: "";
      height: 11px;
      width: 11px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(15px);
      -ms-transform: translateX(15px);
      transform: translateX(15px);
    }
  }
}

.p-radio-wrapper {
  input[type="radio"] {
    -webkit-appearance: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;

    &:before {
      content: "";
      display: inline-block;
      width: 15px;
      height: 15px;
      padding: 3px;
      margin-right: 5px;
      margin-bottom: 3px;
      background-clip: content-box;
      border: 1px solid $primary-main;
      background-color: $white;
      border-radius: 50%;
      vertical-align: middle;
    }

    &:checked:before {
      border: 1px solid $primary-md-dark;
      background-color: $primary-md-dark;
    }
  }
}
