@mixin c-img($border: none, $round: false) {
    position: relative;
    width: 100%;
    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
    img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        @if $round {
            border-radius: 50%;
        } @else {
            border-radius: 5px;
        }
        background-color: $white;
        border: $border;
    }
}

/*
c- custom
-s square
-r round
-b border
*/

.c-img-s { @include c-img; }
.c-img-s-b-dark { @include c-img(1px solid $primary-dark) }
.c-img-r { @include c-img(none, true) }
.c-img-r-b-dark { @include c-img(1px solid $primary-dark, true) }
