@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins/_breakpoints";
@import "./node_modules/bootstrap/scss/mixins/_hover";
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

::ng-deep .login-modal .modal-content {
  width: 85% !important;
  -webkit-border-radius: 1rem !important;
  -moz-border-radius: 1rem !important;
  border-radius: 1rem !important;
  border: none;
  overflow: auto;
}

::ng-deep .common-modal .modal-content {
  -webkit-border-radius: 1rem !important;
  -moz-border-radius: 1rem !important;
  border-radius: 1rem !important;
  border: none;
  overflow: auto;
}

::ng-deep .lp-modal .modal-content {
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  border-radius: 0px !important;
  border: none;
  overflow: auto;
}
