::ng-deep .ng2-tag-input {
    border-bottom: 1px solid $primary-main !important;
    border: 1px solid $primary-main;
    border-radius: 5px;
    padding: 5px 10px !important;
}

::ng-deep .ng2-tag-input.ng2-tag-input--focused {
    border-bottom: 1px solid $primary-main !important;
    border: 1px solid $primary-dark;
}

::ng-deep tag:not(.readonly):not(.tag--editing):active,
::ng-deep tag:not(.readonly):not(.tag--editing):focus {
    background: $primary-md-dark !important;
}

::ng-deep .ng2-tag-input__text-input {
    color: $primary-darkest;
    &::placeholder {
        color: $primary-darkest;
        opacity: .5; /* Firefox */
    }
    &:-ms-input-placeholder {
        color: $primary-darkest; /* Internet Explorer 10-11 */
        opacity: .5;
    }
    &::-ms-input-placeholder {
        color: $primary-darkest; /* Microsoft Edge */
        opacity: .5;
    }
}
