.txt-p-darkest { color: $primary-darkest !important; }
.txt-p-md-dark { color: $primary-md-dark !important; }
.txt-p-dark { color: $primary-dark !important; }
.txt-p-main { color: $primary-main !important; }
.txt-s-main { color: $secondary-main !important; }
.txt-t-main { color: $ternary-main !important; }
.txt-t-dark { color: $ternary-dark !important; }

.fs-16 { font-size: 16px !important; }
.fs-14 { font-size: 14px !important; }
.fs-12 { font-size: 12px !important; }

@include media-breakpoint-only(md) {
  .fs-md-16 { font-size: 16px !important; }
  .fs-md-14 { font-size: 14px !important; }
  .fs-md-12 { font-size: 12px !important; }
}

@mixin txt-truncate {
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px; /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

@mixin txt-truncate-1 {
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 18px; /* fallback */
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.txt-truncate { @include txt-truncate; }
.txt-truncate-1 { @include txt-truncate-1; }

.txt-bold { font-weight: 500; }
.txt-light { opacity: .5; }
.txt-capitalize { text-transform: capitalize; }

i {
  @include font-size(21px);
}

@mixin title {
  font-weight: 700;
  text-transform: capitalize;
}

@mixin txt-dark {
  color: $primary-darkest;
}

@mixin txt-light {
  color: $primary-lightest;
}

@mixin fa-icon ($unicode) {
  content: "#{$unicode}";
  font-family: FontAwesome;
}