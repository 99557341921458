
/*
Customize button

c- --> custom 
-p --> primary
-s --> secondary
-o --> outline
-l --> link
-r --> radius
*/

@mixin c-btn-group($aling-right: false) {
  width: 100%;
  @if $aling-right {
    text-align: right;
    button {
      margin-left: 10px;
    }
  } @else {
    text-align: left;
    button {
      margin-right: 10px;
    }
  }
}

@mixin c-btn-group-block {
  width: 100%;
  display: flex;
  button {
    flex: 1;
    width: 100%;
    margin-right: 10px;
    &:last-child {
      margin-right: 0px;
    }
  }
}

.c-btn-group { @include c-btn-group; }
.c-btn-group-rtl { @include c-btn-group(true); }
.c-btn-group-block { @include c-btn-group-block; }
.c-lg-btn-group {
  @include media-breakpoint-up(lg) { 
    @include c-btn-group;
  }
}
.c-lg-btn-group-rtl { 
  @include media-breakpoint-up(lg) { 
    @include c-btn-group(true); 
  }
}
.c-md-btn-group-block { 
  @include media-breakpoint-down(md) { 
    @include c-btn-group-block;
  }
}

@mixin c-btn($color, $bg) {
  text-transform: capitalize;
  text-align: center;
  box-shadow: none;
  outline: none;
  border: 1px solid $bg;
  background-color: $bg;
  color: $color;
  border-radius: 5px;
  padding: 8px 10px;
  @include c-btn-loading;
  @include media-breakpoint-up(lg) {
    &:hover:enabled {
      @include transform(translateY(-3px));
      @include transition(.3s ease);
      color: $color;
    }
  }
  &:active:enabled {
    background-color: darken($bg, 15%);
  }
  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }
}

@mixin c-btn-o($color, $bg, $border) {
  text-transform: capitalize;
  text-align: center;
  box-shadow: none;
  outline: none;
  border: 1px solid $border;
  background-color: $bg;
  color: $color;
  border-radius: 5px;
  padding: 8px 10px;
  @include c-btn-loading;
  @include media-breakpoint-up(lg) {
    &:hover:enabled {
      @include transform(translateY(-3px));
      @include transition(.3s ease);
      background-color: $border;
      color: $bg;
    }
  }
  &:active:enabled {
    background-color: darken($border, 15%);
    color: $bg;
    border-color: darken($border, 15%);
  }
  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }
}

@mixin c-btn-loading {
  position: relative;
  .btn-label {
    visibility: visible;
    opacity: 1;
    z-index: 1;
  }
  .load-icon {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &.loading {
    .btn-label {
      visibility: hidden;
      opacity: 0;
    }
    .load-icon {
      visibility: visible;
      opacity: 1;
    }
  }
}

// buttons size
@mixin c-btn-lg {
  padding: 10px 15px !important;
  font-size: 16px;
}

@mixin c-btn-md {
  padding: 8px 10px !important;
  font-size: 16px;
}

@mixin c-btn-sm {
  padding: 5px 8px !important;
  font-size: 12px;
}

.c-btn-lg { @include c-btn-lg; }
.c-btn-md { @include c-btn-md; }
.c-btn-sm { @include c-btn-sm; }
.c-md-btn-sm {
  @include media-breakpoint-down(md) {
    @include c-btn-sm;
  }
}
.c-sm-btn-sm {
  @include media-breakpoint-down(xs) {
    @include c-btn-sm;
  }
}

.c-btn-block { width: 100%; }
.c-md-btn-block {
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

// mixin buttons
@mixin btn-p-darkest {
  @include c-btn($white, $primary-darkest);
}

@mixin btn-p-md-dark {
  @include c-btn($white, $primary-md-dark);
}

@mixin btn-p-dark {
  @include c-btn($primary-darkest, $primary-dark);
}

@mixin btn-s-main {
  @include c-btn($white, $secondary-main);
}

@mixin btn-t-main {
  @include c-btn($white, $ternary-main);
}

@mixin btn-t-dark {
  @include c-btn($white, $ternary-dark);
}

// mixin buttons link
@mixin btn-p-darkest-l {
  @include c-btn($primary-darkest, $white);
}

@mixin btn-p-md-dark-l {
  @include c-btn($primary-md-dark, $white);
}

@mixin btn-s-main-l {
  @include c-btn($secondary-main, $white);
}

@mixin btn-t-main-l {
  @include c-btn($ternary-main, $white);
}

@mixin btn-t-dark-l {
  @include c-btn($ternary-dark, $white);
}

// buttons outline
@mixin btn-p-darkest-o {
  @include c-btn-o($primary-darkest, $white, $primary-darkest);
}

@mixin btn-p-md-dark-o {
  @include c-btn-o($primary-md-dark, $white, $primary-md-dark);
}

@mixin btn-p-dark-o {
  @include c-btn-o($primary-darkest, $white, $primary-dark);
}

@mixin btn-s-main-o {
  @include c-btn-o($secondary-main, $white, $secondary-main);
}

@mixin btn-t-main-o {
  @include c-btn-o($ternary-main, $white, $ternary-main);
}

@mixin btn-t-dark-o {
  @include c-btn-o($ternary-dark, $white, $ternary-dark);
}

// buttons
.btn-p-darkest { @include btn-p-darkest; }
.btn-p-md-dark { @include btn-p-md-dark; }
.btn-p-dark { @include btn-p-dark; }
.btn-s-main { @include btn-s-main; }
.btn-t-main { @include btn-t-main; }
.btn-t-dark { @include btn-t-dark; }
// buttons link
.btn-p-darkest-l { @include btn-p-darkest-l; }
.btn-p-md-dark-l { @include btn-p-md-dark-l; }
.btn-s-main-l { @include btn-s-main-l; }
.btn-t-main-l { @include btn-t-main-l; }
.btn-t-dark-l { @include btn-t-dark-l; }
// buttons outline
.btn-p-darkest-o { @include btn-p-darkest-o; }
.btn-p-md-dark-o { @include btn-p-md-dark-o; }
.btn-p-dark-o { @include btn-p-dark-o; }
.btn-s-main-o { @include btn-s-main-o; }
.btn-t-main-o { @include btn-t-main-o; }
.btn-t-dark-o { @include btn-t-dark-o; }