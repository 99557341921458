.toast-top-center {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3000;
  min-width: 300px;
}

#toast-container > .toast {
  background-image: none !important;
  margin: 0 auto;
  background-color: $ternary-light;
  color: $ternary-dark;
  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
  z-index: 3000;
}