@mixin c-badge-p-darkest {
  font-weight: 400;
  background-color: $primary-darkest;
  color: $primary-lightest;
}

@mixin c-badge-p-dark {
  font-weight: 400;
  background-color: $primary-dark;
  color: $primary-darkest;
}

@mixin c-badge-p-main {
  font-weight: 400;
  background-color: $primary-main;
  color: $primary-darkest;
}

.c-badge-p-darkest { @include c-badge-p-darkest() }
.c-badge-p-dark { @include c-badge-p-dark() }
.c-badge-p-main { @include c-badge-p-main() }