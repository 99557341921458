// @import url('https://fonts.googleapis.com/css2?family=Gaegu:wght@300;400;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

html, body { height: 100%; }
body { 
  position: relative;
  margin: 0; 
  font-family: Roboto, "Helvetica Neue", sans-serif; 
  line-height: normal;
  font-size: 14px;
  color: $primary-darkest;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-color: #F6F6F6;
}

a, button {
  cursor: pointer;
  box-shadow: none;
  outline: none;
  -webkit-appearance: none !important;
  text-decoration: none;
}