.modal-wrapper {
    padding: 15px;
    position: relative;
    .close {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
    }
    .title {
        color: $primary-darkest;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
    }
}

@include media-breakpoint-down(md) {
    .modal-wrapper {
        .title {
            font-size: 16px;
            margin-bottom: 30px;
        }
    }
}